import * as Yup from "yup";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "../../../redux/reduxStore";
import {
  BackDropCloseAction,
  BackDropOpenAction,
} from "../../../redux/backdropStore";
import { loginService } from "../../../services/loginService";
import { ResponseModel } from "../../../types/ResponseModel";
import data from "@iconify/icons-eva/menu-2-fill";
import { Form, FormikProvider, useFormik } from "formik";
import React from "react";
import { toaster } from "../../../utils/bToaster";
import { AuthUSerLoginAction } from "../../../redux/authStore";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const appDispatcher = useAppDispatch();
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Lütfen geçerli bir email adresi giriniz.")
      .required("Email adresi gerekli alandır."),
    password: Yup.string()
      .required("Şifre gerekli alandır.")
      .min(3, "Şifreniz 3 karakterden kısa olamaz."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      appDispatcher(BackDropOpenAction());
      loginService
        .login({ userMail: values.email, password: values.password })
        .then(({ data }: { data: ResponseModel }) => {
          if (!data.isSuccess) {
            toaster.error(data.message);
          } else {
            appDispatcher(AuthUSerLoginAction(data.data));
            toaster.success("Giriş işlemi tamamlandı yönlendiriliyorsunuz.");
            navigate("/dashboard")
          }
        })
        .finally(() => {
          appDispatcher(BackDropCloseAction());
          actions.setSubmitting(false);
        });
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email Adresi"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Şifre"
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps("remember")}
                checked={values.remember}
              />
            }
            label="Beni Hatırla"
          />
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Giriş Yap
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
