import { axiosBase } from "./axiosBase";
const TOTAL_PRODUCT_COUNT = "category/totalcount";
const TOTAL_PRODUCT_CLICK = "category/totalclick";
const BASE = "category";
const BULK_DELETE = "category/deletebulk";

export const categoryService = {
  getTotalCount: () => {
    return axiosBase.get(TOTAL_PRODUCT_COUNT);
  },
  getTotalClick: () => {
    return axiosBase.get(TOTAL_PRODUCT_CLICK);
  },
  getAll: () => {
    return axiosBase.get(BASE);
  },
  add: (values: any) => {
    return axiosBase.post(BASE, values);
  },
  deleteBulk: (deletedIds: []) => {
    const dObj = { Ids: deletedIds };
    return axiosBase.post(BULK_DELETE, dObj);
  },
  update: (product: any) => {
    return axiosBase.put(`${BASE}/${product.id}`, product);
  },
};
