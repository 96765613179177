import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import shoppingCartFill from "@iconify/icons-eva/shopping-cart-fill";
import folderFill from "@iconify/icons-eva/folder-fill";
import link2Fill from "@iconify/icons-eva/link-2-fill";
import pageConstants from "../../utils/pageConstants";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: "Istatistikler",
    path: pageConstants.app.fullUrl,
    icon: getIcon(pieChart2Fill),
    isChild: false,
    parent: null,
  },
  {
    title: "Ürün",
    path: pageConstants.products.fullUrl,
    icon: getIcon(shoppingCartFill),
    isChild: false,
    parent: null,
  },
  {
    title: "Kategori",
    path: pageConstants.category.fullUrl,
    icon: getIcon(folderFill),
    isChild: true,
    parent: null,
  },
  {
    title: "Link",
    path: pageConstants.links.fullUrl,
    icon: getIcon(link2Fill),
    isChild: true,
    parent: null,
  },
];

export default sidebarConfig;
