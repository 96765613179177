import { Link as RouterLink, Outlet } from "react-router-dom";
// material
// components
import { AppBar, Grid, Box, Toolbar, Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import { Container } from "@mui/material";
import Logo from "../components/Logo";
import React from "react";
import { Link } from "react-router-dom";

export default function CustomerLayout() {
  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: "crimson",
          minHeight: "100vh",
          background: "url('/static/main/bg.jfif')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        <Grid xs={1} item></Grid>
        <Grid xs={10} item>
          <AppBar
            sx={{
              backgroundColor: "white", borderRadius:'16px',
            }}
            position="static"
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <Logo />
              </IconButton>
              <Box
                display="flex"
                sx={{ width: "100%" }}
                justifyContent={"center"}
              ></Box>
            </Toolbar>
          </AppBar>
          <Outlet />
          <Grid sx={{ marginTop: (theme) => theme.spacing(1) }} item xs={12}>
            <AppBar position="static" sx={{ backgroundColor: "white",borderRadius:"16px" }}>
              <Container
                maxWidth="md"
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Toolbar>
                  <Typography sx={{ color: "black" }} variant="body2">
                    Telif hakkı ve kopya; 2022
                    <Typography
                      variant="subtitle2"
                      sx={{
                        display: "inline",
                        marginLeft: (theme) => theme.spacing(1),
                        marginRight: (theme) => theme.spacing(1),
                      }}
                      color="primary"
                    >
                      <a
                        style={{ textDecoration: "inherit", color: "inherit" }}
                        href="http://solaravm.com"
                        target="_blank"
                      >
                        {" "}
                        Solar AVM.
                      </a>
                    </Typography>
                    Tüm hakları Saklıdır.
                  </Typography>
                </Toolbar>
              </Container>
            </AppBar>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
