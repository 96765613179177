import { UserModel } from "./../types/UserModel";
import { ActionType } from "./ActionType";
const initialStore: { user: UserModel } = {
  user: {
    name: "",
    email: "",
    imageUrl: "",
    roles: [],
    auth: {
      accessToken: "",
      refreshToken: "",
    },
  },
};

const USER_LOGIN: string = "userloginauth";
const USER_LOGOUT: string = "userlogoutauth";

export const AuthUSerLoginAction = (userModel: UserModel): ActionType => {
  return { type: USER_LOGIN, payload: userModel };
};

export const AuthUSerLogoutAction = () => {
  return { type: USER_LOGOUT, payload: null };
};
export type AuthStoreType = typeof initialStore;
export const authStore = (
  store = initialStore,
  action: { type: string; payload: UserModel }
) => {
  if (action.type === USER_LOGIN) {
    return { ...store, user: { ...action.payload } };
  }
  if (action.type === USER_LOGOUT) {
    return initialStore;
  }
  return store;
};
