import { Icon } from "@iconify/react";
import androidFilled from "@iconify/icons-ant-design/android-filled";
import linkFill from "@iconify/icons-eva/link-fill";
// material
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
// utils
import { fShortenNumber } from "../../../utils/formatNumber";
import { useEffect, useState } from "react";
import React from "react";
import { ResponseModel } from "../../../types/ResponseModel";
import { linkService } from "../../../services/linkService";

// ----------------------------------------------------------------------
const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(5, 0),
  color: theme.palette.info.darker,
  backgroundColor: theme.palette.info.lighter,
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: theme.palette.info.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.info.dark,
    0
  )} 0%, ${alpha(theme.palette.info.dark, 0.24)} 100%)`,
}));

// ----------------------------------------------------------------------

export default function DashboardLink() {
  const [totalLink, setTotalLink] = useState(0);
  useEffect(() => {
    linkService.getTotalCount().then(({ data }: { data: ResponseModel }) => {
      setTotalLink(data.data);
    });
  }, []);
  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={linkFill} width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3">{fShortenNumber(totalLink)}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Link Sayısı
      </Typography>
    </RootStyle>
  );
}
