import {ActionType} from "./ActionType";

export interface BackDropInterface {
    open : boolean
}


const BACKDROP_OPEN : string = "backdropopen";
const BACKDROP_CLOSE : string = "backdropclose";

const initialState :BackDropInterface = {
    open : false
}

export function BackDropOpenAction():ActionType{
    return {type : BACKDROP_OPEN , payload : null}
}

export function BackDropCloseAction():ActionType{
    return {type : BACKDROP_CLOSE , payload : null}
}

export const backdropStore  = (state: BackDropInterface = initialState,action : ActionType) => {
        if(action.type === BACKDROP_OPEN)
            return {...state,open : true}
        else if(action.type === BACKDROP_CLOSE)
            return {...state,open : false}
        return state;
}