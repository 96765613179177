import React, { useEffect } from "react";
import {
  Avatar,
  Box,
  Card,
  CardMedia,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

export const CustomerDashboard = () => {
  useEffect(() => {
    console.log("customer dashboard loaded.");
  });
  return (
    <React.Fragment>

<Grid
        item
        xs={12}
        sx={{
          marginTop: (theme) => theme.spacing(2),
        }}
      >
        <Card
          sx={{ padding: (theme) => theme.spacing(2) }}
          variant={"outlined"}
        >
          <Box
            display={"flex"}
            sx={{ marginBottom: (theme) => theme.spacing(2) }}
            justifyContent={"center"}
          >
            <Typography variant={"h4"} color={"primary"}>
              İhtiyacınız olan ürünü bulalım !
            </Typography>
          </Box>
          <Link
            style={{ textDecoration: "inherit", color: "inherit" }}
            to={"/customer/calculator"}
          >
            <Grid
              sx={{
                marginBottom: (theme) => theme.spacing(1),
              }}
            >
              <Paper
                variant={"outlined"}
                sx={{
                  padding: (theme) => theme.spacing(1),
                  width: {
                    md: "100%",
                    lg: "33%",
                  },
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  flexWrap={"wrap"}
                  sx={{
                    justifyContent: {
                      sm: "center",
                      md: "space-between",
                    },
                  }}
                >
                  <Grid
                    sx={{
                      flexBasis: {
                        sx: "100%",
                        md: "30%",
                      },
                    }}
                  >
                    <Avatar
                      alt="Paket Hesaplayıcı"
                      src="/static/main/calculator.svg"
                      sx={{ width: 86, height: 86 }}
                    />
                  </Grid>
                  <Grid
                    sx={{
                      flexBasis: {
                        sx: "100%",
                        md: "70%",
                      },
                    }}
                  >
                    <Grid>
                      <Typography color={"primary"} variant={"subtitle1"}>
                        Solar Paket Hesaplayıcı
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography variant={"body1"} sx>
                        Paket hesaplayıcıya gitmek için tıklayınız.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Link>
        </Card>
      </Grid>


      <Grid
        item
        xs={12}
        sx={{
          marginTop: (theme) => theme.spacing(2),
        }}
      >
        <Card
          sx={{ padding: (theme) => theme.spacing(2) }}
          variant={"outlined"}
        >
          <Box
            display={"flex"}
            sx={{ marginBottom: (theme) => theme.spacing(2) }}
            justifyContent={"center"}
          >
            <Typography variant={"h4"} color={"primary"}>
              Neden solar ürünleri kullanmalıyım?
            </Typography>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            flexWrap={"wrap"}
            justifyContent={"center"}
          >
            <Grid
              sx={{
                flexBasis: {
                  sm: "100%",
                  md: "30%",
                },
              }}
            >
              <Grid
                sx={{
                  marginBottom: (theme) => theme.spacing(1),
                }}
              >
                <Paper
                  variant={"outlined"}
                  sx={{
                    padding: (theme) => theme.spacing(1),
                  }}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                  >
                    <Grid
                      sx={{
                        flexBasis: {
                          sx: "100%",
                          md: "30%",
                        },
                      }}
                    >
                      <Avatar
                        alt="Uzun Süreli Kullanım"
                        src="/static/main/ser_img_1.png"
                        sx={{
                          width: 56,
                          height: 56,
                        }}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        flexBasis: {
                          sx: "100%",
                          md: "70%",
                        },
                      }}
                    >
                      <Grid>
                        <Typography color={"primary"} variant={"subtitle2"}>
                          Uzun süreli kullanım
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography variant={"caption"}>
                          20-30 yılı bulan kullanım süresi
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <Grid
                sx={{
                  marginBottom: (theme) => theme.spacing(1),
                }}
              >
                <Paper
                  variant={"outlined"}
                  sx={{
                    padding: (theme) => theme.spacing(1),
                  }}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                  >
                    <Grid
                      sx={{
                        flexBasis: {
                          sx: "100%",
                          md: "30%",
                        },
                      }}
                    >
                      <Avatar
                        alt="Doğa Dostu Enerji"
                        src="/static/main/ser_img_2.png"
                        sx={{ width: 56, height: 56 }}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        flexBasis: {
                          sx: "100%",
                          md: "70%",
                        },
                      }}
                    >
                      <Grid>
                        <Typography color={"primary"} variant={"subtitle2"}>
                          Doğa dostu enerji
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography variant={"caption"}>
                          Sıfır CO2 salınımı
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <Grid
                sx={{
                  marginBottom: (theme) => theme.spacing(1),
                }}
              >
                <Paper
                  variant={"outlined"}
                  sx={{
                    padding: (theme) => theme.spacing(1),
                  }}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                  >
                    <Grid
                      sx={{
                        flexBasis: {
                          sx: "100%",
                          md: "30%",
                        },
                      }}
                    >
                      <Avatar
                        alt="Tekrar Kullanılabilir Enerji"
                        src="/static/main/ser_img_6.png"
                        sx={{ width: 56, height: 56 }}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        flexBasis: {
                          sx: "100%",
                          md: "70%",
                        },
                      }}
                    >
                      <Grid>
                        <Typography color={"primary"} variant={"subtitle2"}>
                          Yenilenebilir Enerji
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography variant={"caption"}>
                          Çevrenin ve gelecek nesillerin dostu
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
            <Grid align="center"
              sx={{
                flexBasis: {
                  sm: "100%",
                  md: "30%",                  
                },
                display:{xs:"none",sm:"none",md:"block"}
              }}
            >
            <Link
            style={{ textDecoration: "inherit", color: "inherit" }}
            to={"/customer/calculator"}
          >
              <CardMedia sx={{width:"50%"}} component="img" image="/static/main/s1.png" alt="s1" />
              </Link>
            </Grid>
            <Grid
              sx={{
                flexBasis: {
                  sm: "100%",
                  md: "30%",
                },
              }}
            >
              <Grid
                sx={{
                  marginBottom: (theme) => theme.spacing(1),
                }}
              >
                <Paper
                  variant={"outlined"}
                  sx={{
                    padding: (theme) => theme.spacing(1),
                  }}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                  >
                    <Grid
                      sx={{
                        flexBasis: {
                          sx: "100%",
                          md: "30%",
                        },
                      }}
                    >
                      <Avatar
                        alt="Uzun Süreli Kullanım"
                        src="/static/main/ser_img_6.png"
                        sx={{ width: 56, height: 56 }}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        flexBasis: {
                          sx: "100%",
                          md: "70%",
                        },
                      }}
                    >
                      <Grid>
                        <Typography color={"primary"} variant={"subtitle2"}>
                          Tasarruf
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography variant={"caption"}>
                          Daha düşük maliyetle daha fazla harca
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <Grid
                sx={{
                  marginBottom: (theme) => theme.spacing(1),
                }}
              >
                <Paper
                  variant={"outlined"}
                  sx={{
                    padding: (theme) => theme.spacing(1),
                  }}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                  >
                    <Grid
                      sx={{
                        flexBasis: {
                          sx: "100%",
                          md: "30%",
                        },
                      }}
                    >
                      <Avatar
                        alt="Uzun Süreli Kullanım"
                        src="/static/main/ser_img_4.png"
                        sx={{ width: 56, height: 56 }}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        flexBasis: {
                          sx: "100%",
                          md: "70%",
                        },
                      }}
                    >
                      <Grid>
                        <Typography color={"primary"} variant={"subtitle2"}>
                          Düşük Maliyetli Kurulum
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography variant={"caption"}>
                          Kendi sistemini kendin kur
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <Grid
                sx={{
                  marginBottom: (theme) => theme.spacing(1),
                }}
              >
                <Paper
                  variant={"outlined"}
                  sx={{
                    padding: (theme) => theme.spacing(1),
                  }}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                  >
                    <Grid
                      sx={{
                        flexBasis: {
                          sx: "100%",
                          md: "30%",
                        },
                      }}
                    >
                      <Avatar
                        alt="Uzun Süreli Kullanım"
                        src="/static/main/ser_img_5.png"
                        sx={{ width: 56, height: 56 }}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        flexBasis: {
                          sx: "100%",
                          md: "70%",
                        },
                      }}
                    >
                      <Grid>
                        <Typography color={"primary"} variant={"subtitle2"}>
                          Hızlı Kurulum
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography variant={"caption"}>
                          Pratik kurulum rahatlığı
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>

    </React.Fragment>
  );
};
