import * as React from "react";

import { useState, useEffect } from "react";

import { FormControl, MenuItem, Select } from "@mui/material";
import { InputLabel } from "@mui/material";
import { FormHelperText } from "@mui/material";
import { OutlinedInput } from "@mui/material";
import { EditRounded } from "@mui/icons-material";

import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Page from "../components/Page";
import * as Yup from "yup";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

import Switch from "@mui/material/Switch";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AddIcon from "@mui/icons-material/Add";
import { categoryService } from "../services/categoryService";
import _ from "lodash";
import LinkAdd from "./LinksAdd";
import LinkEdit from "./LinksEdit";

import { Stack, Avatar, Button, Container, Typography } from "@mui/material";
import { toaster } from "../utils/bToaster";
import { urlHelper } from "../utils/urlHelper";
import { Formik } from "formik";
import { linkService } from "../services/linkService";

export default function LinksEdit(props) {
  const initialLink = props.initialLink;
  const [image64, setImage64] = useState(initialLink.imageUrl);

  const Input = styled("input")({
    display: "none",
  });

  const readBase64Str = (file) => {
    if (file) {
      var reader = new FileReader();
      reader.onload = function (e) {
        const base64 = e.target.result;
        setImage64(base64);
      };
      reader.readAsDataURL(file);
    }
  };

  const imageChanged = (e) => {
    readBase64Str(e.target.files[0]);
  };

  const removeImage = (e) => {
    setImage64("");
    e.preventDefault();
  };

  const renderImage = () => {
    if (image64) {
      return (
        <React.Fragment>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Avatar alt="UImg" src={image64} sx={{ width: 150, height: 150 }} />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <Button
              onClick={removeImage}
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            startIcon={<AddAPhotoIcon />}
            color="primary"
            aria-label="upload picture"
            component="span"
            variant="contained"
          >
            <AddAPhotoIcon sx={{ fontSize: 140 }} />
          </IconButton>
        </div>
      );
    }
  };

  const [isActive, setIsActive] = React.useState(initialLink.isActive);
  return (
    <Formik
      initialValues={{
        name: initialLink.name,
        imageUrl: initialLink.imageUrl,

        minPower: initialLink.minPower,
        maxPower: initialLink.maxPower,
        explanation: initialLink.explanation,
        url: initialLink.url,
      }}
      validationSchema={Yup.object({
        name: Yup.string()
          .required("Link ismini boş bırakamazsınız.")
          .min(3, "Link ismi en az 3 karkater olmalıdır."),
        minPower: Yup.number()
          .required("Minimum güç boş bırakılamaz")
          .min(1, "En az 1 girebilirsiniz."),
        maxPower: Yup.number()
          .required("Maximum güç boş bırakılamaz")
          .min(1, "En az 1 girebilirsiniz."),
        explanation: Yup.string()
          .required("Açıklama boş bırakılamaz")
          .min(3, "En az 3 karakterlik bir açıklama giriniz."),
        url: Yup.string()
          .required("Ürün linki boş bırakılamaz")
          .min(7, "En az 7 karakterlik bir url giriniz."),
      })}
      onSubmit={async (values, formikThings) => {
        formikThings.setSubmitting(true);
        if (!urlHelper.checkLinkIsValid(values.url)) {
          formikThings.setErrors({ url: "Geçerli bir url giriniz." });
          return;
        }
        const nValues = {
          ...values,
          imageUrl: image64,
          isActive,
          id: initialLink.id,
        };
        linkService
          .update(nValues)
          .then(({ data }) => {
            const link = data.data;
            toaster.success(
              `${link.name} isimli linki başarı ile güncellediniz.`
            );
            props.linkUpdated(link);
          })
          .finally(() => {
            formikThings.setSubmitting(false);
          });
      }}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <Page title="Linki Ekleme">
            <Container>
              <Typography variant="h4" gutterBottom>
                Linki Ekleme
              </Typography>
              <Stack direction="row" spacing={3}>
                <Typography sx={{ pb: 2 }} variant="body2" gutterBottom>
                  Ana Ekran
                </Typography>
                <ArrowRightIcon />
                <Typography sx={{ pb: 2 }} variant="body2" gutterBottom>
                  Link
                </Typography>
                <ArrowRightIcon />
                <Typography sx={{ pb: 2 }} variant="body2" gutterBottom>
                  Link Ekleme
                </Typography>
              </Stack>
              <Box
                sx={{
                  "& > :not(style)": { m: 1 },
                  maxWidth: "100%",
                  marginLeft: "10%",
                }}
                noValidate
                autoComplete="off"
              >
                <Grid container>
                  <Grid item xs={12} sm={6} md={3}>
                    <label htmlFor="icon-button-file">
                      {renderImage()}
                      {!image64 && (
                        <Input
                          onChange={(e) => imageChanged(e)}
                          accept="image/*"
                          id="icon-button-file"
                          type="file"
                        />
                      )}
                    </label>
                    <Box sx={{ mt: 5 }} />

                    <Divider orientation="horizontal" flexItem />
                    <Box sx={{ mt: 5 }} />

                    <Grid item xs={12} sm={6} md={6}>
                      <Stack direction="row" spacing={3}>
                        <Typography variant="body1" sx={{ pt: 1 }} gutterBottom>
                          Görünürlük:
                        </Typography>
                        <Switch
                          value={"qwe"}
                          checked={isActive}
                          onChange={() => {
                            console.log(!isActive);
                            console.log("active changed");
                            setIsActive(!isActive);
                          }}
                          name="loading"
                          color="primary"
                        />
                      </Stack>
                      <FormHelperText id="isActive">
                        Link Aktif mi?
                      </FormHelperText>
                    </Grid>
                  </Grid>
                  <Divider orientation="vertical" sx={{ ml: 5 }} flexItem />
                  <Grid item xs={12} sm={6} md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        "& > :not(style)": {
                          m: 1,
                          maxWidth: "100%",
                        },
                      }}
                    >
                      <Stack sx={{ width: "100%" }} direction="row" spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                          <FormControl sx={{ width: "100%" }}>
                            <FormControl sx={{ width: "100%" }}>
                              <InputLabel htmlFor="product_name">
                                Link İsmi
                              </InputLabel>
                              <OutlinedInput
                                {...formik.getFieldProps("name")}
                                id="product_name"
                                label="Power"
                              />
                            </FormControl>
                            {formik.touched.name && formik.errors.name ? (
                              <FormHelperText
                                sx={{
                                  color: (theme) => theme.palette.error.main,
                                }}
                              >
                                {formik.errors.name}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>
                      </Stack>
                      <Stack sx={{ width: "100%" }} direction="row" spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                          <FormControl sx={{ width: "100%" }}>
                            <FormControl sx={{ width: "100%" }}>
                              <InputLabel htmlFor="product_name">
                                Min Güç
                              </InputLabel>
                              <OutlinedInput
                                {...formik.getFieldProps("minPower")}
                                id="product_name"
                                label="Min Güç"
                              />
                            </FormControl>
                            {formik.touched.minPower &&
                            formik.errors.minPower ? (
                              <FormHelperText
                                sx={{
                                  color: (theme) => theme.palette.error.main,
                                }}
                              >
                                {formik.errors.minPower}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <FormControl sx={{ width: "100%" }}>
                            <FormControl sx={{ width: "100%" }}>
                              <InputLabel htmlFor="product_order">
                                Max Güç
                              </InputLabel>
                              <OutlinedInput
                                {...formik.getFieldProps("maxPower")}
                                id="product_order"
                                label="Max Güç"
                              />
                            </FormControl>
                            {formik.touched.maxPower &&
                            formik.errors.maxPower ? (
                              <FormHelperText
                                sx={{
                                  color: (theme) => theme.palette.error.main,
                                }}
                              >
                                {formik.errors.maxPower}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>
                      </Stack>
                      <Stack sx={{ width: "100%" }} direction="row" spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                          <FormControl sx={{ width: "100%" }}>
                            <FormControl sx={{ width: "100%" }}>
                              <InputLabel htmlFor="product_link">
                                Link Url
                              </InputLabel>
                              <OutlinedInput
                                {...formik.getFieldProps("url")}
                                id="product_link"
                                label="Link Url"
                              />
                            </FormControl>
                            {formik.touched.url && formik.errors.url ? (
                              <FormHelperText
                                sx={{
                                  color: (theme) => theme.palette.error.main,
                                }}
                              >
                                {formik.errors.url}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>
                      </Stack>
                      <Stack sx={{ width: "100%" }} direction="row" spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                          <FormControl sx={{ width: "100%" }}>
                            <FormControl sx={{ width: "100%" }}>
                              <InputLabel htmlFor="product_name">
                                Link Açıklaması
                              </InputLabel>
                              <OutlinedInput
                                {...formik.getFieldProps("explanation")}
                                id="product_name"
                                label="Açıklama"
                              />
                            </FormControl>
                            {formik.touched.explanation &&
                            formik.errors.explanation ? (
                              <FormHelperText
                                sx={{
                                  color: (theme) => theme.palette.error.main,
                                }}
                              >
                                {formik.errors.explanation}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>
                      </Stack>

                      <Grid
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                        item
                        xs={12}
                      >
                        <Button
                          variant="contained"
                          margin-top="225px"
                          endIcon={<EditRounded />}
                          type={"submit"}
                        >
                          Düzenle
                        </Button>
                      </Grid>

                      <Paper elevation={0} />
                    </Box>
                  </Grid>{" "}
                </Grid>
              </Box>
            </Container>
          </Page>
        </form>
      )}
    </Formik>
  );
}
