import {Icon} from "@iconify/react";
import pricetagsFill from "@iconify/icons-eva/pricetags-fill";
// material
import {alpha, styled, Theme} from "@mui/material/styles";
// utils
import {fShortenNumber} from "../../../utils/formatNumber";
import {productService} from "../../../services/productService";
import React, {useEffect, useState} from "react";
import {ResponseModel} from "../../../types/ResponseModel";
import {Link} from "react-router-dom";
import { Typography, Card } from "@mui/material";
import pageConstants from "../../../utils/pageConstants";
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({theme}) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter,
}));

const IconWrapperStyle = styled("div")(({theme}) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
      theme.palette.primary.dark,
      0
  )} 0%, ${alpha(theme.palette.primary.dark, 0.24)} 100%)`,
}));


// ----------------------------------------------------------------------

export default function DasboardProduct() {
  const [totalProduct, setTotalProduct] = useState(0);
  useEffect(() => {
    productService.getTotalCount().then(({data}: { data: ResponseModel }) => {
      setTotalProduct(data.data);
    });
  }, []);
  // @ts-ignore
  return (
      <RootStyle>
        <IconWrapperStyle>
          <Icon icon={pricetagsFill} width={24} height={24}/>
        </IconWrapperStyle>
        <Typography variant="h3">{fShortenNumber(totalProduct)}</Typography>
        <Link style={{textDecoration : 'inherit' , color  :'inherit'}} to={pageConstants.products.fullUrl}>Ürün Sayısı</Link>
      </RootStyle>
  );
}
