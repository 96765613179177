import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Paper,
  Typography,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  TextField,
  Modal,
  Fade,
  Backdrop,
  AppBar,
  Toolbar,
  Container,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle 
} from "@mui/material";
import { categoryService } from "../services/categoryService";
import { productService } from "../services/productService";
import {
  BoltRounded,
  CallMissedOutgoingOutlined,
  DeleteRounded,
  ErrorOutline,
  FolderRounded,
  VolumeUp,
  LightModeRounded,
  BatteryChargingFullRounded,
  CableRounded,
  ElectricalServicesRounded,
  AutoAwesomeMotionSharp,
  MapsHomeWorkRounded,
} from "@mui/icons-material";
import { toaster } from "../utils/bToaster";
import { linkService } from "../services/linkService";
import _ from "lodash";
import ProductAutoComplete from "../components/_dashboard/customer/ProductAutoComplete";
import { typography } from "@mui/system";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export const CustomerMain = () => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const foundedLinksRef = useRef(null);
  const [tempObj, setTempObj] = useState({});
  const [foundedLinks, setFoundedLinks] = useState([]);
  const [foundedLinkModalOpen, setFoundedLinkModalOpen] = useState(false);
  const [pData,setPData] = useState([]);

  const handleGoLink = (link) => {
    linkService.incrementClick(link.id).then(() => {
      toaster.success("Yönlendirme işlemi başarı ile sağlandı.");
    });
    window.open(link.url, "_blank").focus();
  };

  const calculateTotalArr = (newTemp) => {
    const tempArr = [];
    Object.keys(newTemp).forEach((key) => {
      tempArr.push(...newTemp[key]);
    });
    return tempArr;
  };

  const handleSelectedsNew = (products) =>{
    setSelectedProducts(products);
  }

  const handleAutoCompleteSelecteds = (catId, products) => {
    const newTemp = { ...tempObj, ...{ [catId]: products } };
    console.log(newTemp);

    let calculatedTotalArr = [...calculateTotalArr(newTemp)];
    calculatedTotalArr.forEach((p) => {
      const indexOfSelected = selectedProducts.findIndex(
        (tp) => tp.id === p.id
      );
      if (indexOfSelected >= 0) {
        const oldIndex = calculatedTotalArr.findIndex((tp) => tp.id === p.id);
        calculatedTotalArr[oldIndex] = selectedProducts[indexOfSelected];
      }
    });
    setTempObj(newTemp);

    setSelectedProducts(calculatedTotalArr);
  };

  const calculatedWatt = selectedProducts.reduce((p, c) => {
    return p + (Number(c.power) * Number(c.count) * Number(c.hourlyUsage));
  }, 0);

  const handleDeleteItemArray = (product) => {
    setFoundedLinks([]);
    const t = selectedProducts
        .filter((p) =>  p.id !== product.id);
    setSelectedProducts(t);
  };

  const handleBlur = (e, product) => {
    if (e.target.value < 0) {
      setSelectedProducts(
        selectedProducts.map((p) =>
          p.id === product.id ? { ...p, power: 0 } : p
        )
      );
    }
  };
  const handleCountBlur = (e, product) => {
    if (e.target.value < 0) {
      setSelectedProducts(
        selectedProducts.map((p) =>
          p.id === product.id ? { ...p, count: 0 } : p
        )
      );
    }
  };
  const handleHourlyUsageBlur = (e, product) => {
    if (e.target.value < 0) {
      setSelectedProducts(
        selectedProducts.map((p) =>
          p.id === product.id ? { ...p, hourlyUsage: 0 } : p
        )
      );
    }
  };

  const handleCalculateClick = () => {
    linkService.checkWattLinks(calculatedWatt).then(({ data }) => {
      const fLinks = data.data;
      setFoundedLinks(fLinks);
      if (fLinks && fLinks.length > 0) {
        setFoundedLinkModalOpen(true);
      } else {
        toaster.error(
          "Üzgünüz , aradığınız kriterleri karşılayabilecek bir paket bulamadık."
        );
      }
    });
  };

  const handleValueChangeArray = (e, product) => {
    if(e.target.value.length>0 && !e.target.value[0].match('[0-9]')) e.target.value = 0
    setFoundedLinks([]);
    setSelectedProducts(
      selectedProducts.map((p) =>
        p.id === product.id ? { ...p, power: e.target.value } : p
      )
    );
  };

  const handleHourlyUsageChangeArray = (e, product) => {
    if(e.target.value.length>0 && !e.target.value[0].match('[0-9]')) e.target.value = 0
    setFoundedLinks([]);
    setSelectedProducts(
      selectedProducts.map((p) =>
        p.id === product.id ? { ...p, hourlyUsage: e.target.value } : p
      )
    );
  };

  const handleCountChangeArray = (e, product) => {
    if(e.target.value.length>0 && !e.target.value[0].match('[0-9]')) e.target.value = 0
    setFoundedLinks([]);
    setSelectedProducts(
      selectedProducts.map((p) =>
        p.id === product.id ? { ...p, count: e.target.value } : p
      )
    );
  };

  const calculatedTotalKW = selectedProducts.reduce(
    (p, c) => p + Number(((c.power * c.hourlyUsage * c.count) / 1000).toFixed(2)),
    0
  );


  const getPDatas = () =>{
    productService
        .pData()
        .then((resp) =>{
          console.log("pdata");
          console.log(resp.data.data);
          let tp  =  resp.data.data
          tp = tp.map((p) =>{
            return {...p,hourlyUsage : 1 , count : 1}
          });
          tp = _.orderBy(tp,["order","asc"]);
          setPData(tp);
        }).catch(
        () =>{
          console.log("an error occured");
        }
    )
  }

  useEffect(() => {
    getPDatas();
    categoryService.getAll().then(({ data }) => {
      let categories = data.data;
      categories = categories.map((category) => {
        const tempProducts = category.products.map((products) => {
          return { ...products, hourlyUsage: 1, count: 1 };
        });
        category.products = tempProducts;
        return {
          ...category,
          products: _.orderBy(category.products, ["order", "asc"]),
        };
      });
      setCategories(categories);
    });

    // productService.getAll().then(({ data }) => {
    //   let products = data.data;
    //   products = products.map((product) => {
    //       return { ...products};
    //   });
    //   setProducts(products);
    // });
  },
    
  []);
  return (
    <React.Fragment>
      <Grid spacing={2} sx={{ marginTop: (theme) => theme.spacing(1) }} container >
        <Grid item xs={12}>
        <Card
            sx={{ padding: (theme) => theme.spacing(1) }}
            variant={"outlined"}
          >
            <Box
              sx={{ marginBottom: (theme) => theme.spacing(1) }}
              justifyContent={"center"}
            >
              <Container maxWidth={"sm"} align={"center"} >
                <Typography variant={"h4"} color={"primary"} >
                  Size En Uygun Solar Paketi Bulalım
                </Typography>
              </Container>
              {/* <Container maxWidth={"sm"} align={"center"}>
                <Typography>
                  Kullandığınız eşyaları listeden seçin, kullanım sürelerini belirtin.
                </Typography>
              </Container> */}
            </Box>
            </Card>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Card
            sx={{ padding: (theme) => theme.spacing(1) }}
            variant={"outlined"}
          >
            <Box
              sx={{ marginBottom: (theme) => theme.spacing(1) }}
              justifyContent={"center"} 
            >
              <Container maxWidth={"sm"} align={"center"} >
                <Typography variant={"h4"} color={"primary"} >
                  Eşya Seç
                </Typography>
              </Container>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              flexWrap={"wrap"}
              sx={{
                justifyContent: {
                  sm: "center",
                  md: "space-between",
                },
              }}
            >
              <Grid
                sx={{
                  flexBasis: {
                    sm: "100%",
                    md: "100%",
                  },
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                {pData && pData.length > 0 && (
                    <Grid
                        sx={{
                          marginBottom: (theme) => theme.spacing(1),
                          marginRight: (theme) => theme.spacing(1),
                          display: "flex",
                        }}
                    >
                      <Paper
                          variant={"outlined"}
                          sx={{
                            padding: (theme) => theme.spacing(1),

                          }}
                      >

                        <Grid
                            align="center"
                            sx={{
                              flexBasis: {
                                sx: "100%",
                                md: "30%",
                              },
                            }}
                        >
                          <Avatar
                              sx={{ width: 56, height: 56 }}
                          />
                        </Grid>
                        <Grid
                            sx={{
                              flexBasis: {
                                sx: "100%",
                                md: "70%",
                              },
                            }}
                        >
                          <Grid>
                            <Typography
                                color={"primary"}
                                variant={"subtitle2"}
                                align={"center"}
                            >
                              Seçim Menüsü
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid sx={{ flexBasis: "100%" }}>
                          <ProductAutoComplete
                              values={
                            selectedProducts
                              }
                              selectedsChanged={(v) => {
                                handleSelectedsNew(v);
                              }}
                              products={pData}
                          />
                        </Grid>

                      </Paper>
                    </Grid>

                )}
              </Grid>

            </Box>
          </Card>
        </Grid>
        {selectedProducts && selectedProducts.length > 0 ? (
          <Grid item xs={12} lg={12}>
            <Paper variant={"outlined"} align={"center"}>
            <Typography variant={"h4"} color={"primary"} sx={{ marginTop: "5px" }}>
                  Seçilen Eşyalar
                </Typography>
              <List>
                {selectedProducts.map((selectedProduct) => {
                  return (
                    <Paper
                      sx={{
                        margin: (theme) => theme.spacing(1),
                      }}
                      variant={"outlined"}
                    >
                      <ListItem
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                        key={selectedProduct.id}
                        secondaryAction={
                          <IconButton
                            onClick={() =>
                              handleDeleteItemArray(selectedProduct)
                            }
                            edge="end"
                            aria-label="delete"
                          >
                            <DeleteRounded />
                          </IconButton>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar src={selectedProduct.img}></Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={selectedProduct.name} />

                        <TextField
                          label="Adet"
                          id="outlined-start-adornment"
                          size="small"
                          sx={{
                            m: 1,
                            flexBasis: {
                              sm: "100%",
                              md: "17%",
                              lg: "17%",
                            },
                          }}
                          type={"number"}
                          value={selectedProduct.count}
                          InputProps={{
                            inputProps: { min: 0 },
                            endAdornment: (
                              <InputAdornment position="end">
                                adet
                              </InputAdornment>
                            ),
                          }}
                          onBlur={(e) =>
                            handleCountBlur(e, selectedProduct)
                          }
                          onChange={(e) =>
                            handleCountChangeArray(e, selectedProduct)
                          }
                        />
                        <TextField
                          label="Güç Değeri"
                          id="outlined-start-adornment"
                          size="small"
                          sx={{
                            m: 1,
                            flexBasis: {
                              sm: "100%",
                              md: "17%",
                              lg: "17%",
                            },
                          }}
                          type={"number"}
                          value={selectedProduct.power}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                watt
                              </InputAdornment>
                            ),
                          }}
                          onBlur={(e) => handleBlur(e, selectedProduct)}
                          onChange={(e) =>
                            handleValueChangeArray(e, selectedProduct)
                          }
                        />
                        <TextField
                          label="Günlük Kullanım"
                          id="outlined-start-adornment"
                          size="small"
                          sx={{
                            m: 1,
                            flexBasis: {
                              sm: "100%",
                              md: "17%",
                              lg: "17%",
                            },
                          }}
                          type={"number"}
                          value={selectedProduct.hourlyUsage}
                          InputProps={{
                            inputProps: { min: 0 },
                            endAdornment: (
                              <InputAdornment position="end">
                                saat
                              </InputAdornment>
                            ),
                          }}
                          onBlur={(e) =>
                            handleHourlyUsageBlur(e, selectedProduct)
                          }
                          onChange={(e) =>
                            handleHourlyUsageChangeArray(e, selectedProduct)
                          }
                        />
                        <TextField
                          label="Günlük Tüketim"
                          id="outlined-start-adornment"
                          size="small"
                          sx={{
                            m: 1,
                            flexBasis: {
                              sm: "100%",
                              md: "17%",
                              lg: "17%",
                            },
                          }}
                          type={"number"}
                          value={Number(
                            (selectedProduct.count *
                              selectedProduct.power *
                              selectedProduct.hourlyUsage) /
                            1000
                          ).toFixed(2)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                KW
                              </InputAdornment>
                            ),
                          }}
                          disabled={true}
                        />
                      </ListItem>
                    </Paper>
                  );
                })}
                <Grid alignItems={"center"}>
                  <Grid
                    sx={{ marginRight: "1rem" }}
                    variant={"h4"}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    <Typography
                      sx={{ padding: (theme) => theme.spacing(1) }}
                      variant={"h4"}
                    >
                      Toplam Tüketim:{" "}
                    </Typography>
                    {selectedProducts && selectedProducts.length > 0 && (
                      <Typography
                        sx={{ padding: (theme) => theme.spacing(1) }}
                        variant={"h4"}
                        color={"primary"}
                      >
                        {" "}
                        {calculatedTotalKW.toFixed(2)} KW
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid alignItems={"center"} sx={{ paddingBottom: "10px" }}>
                  <Grid
                    sx={{ marginRight: "1rem" }}
                    variant={"h4"}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    <Button
                      onClick={handleCalculateClick}
                      variant={"outlined"}
                      color={"success"}
                      size={"large"}
                    >
                      <Typography sx={{ fontWeight:"bold" }}>Uygun Solar Paketi Bul</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </List>
            </Paper>
          </Grid>
        ) : 
          <Grid item xs={12} lg={12}>
            <Paper variant={"outlined"} align={"center"}>
              <Typography variant={"h4"} color={"primary"} sx={{ marginTop: "5px" }}>
               Nasıl Kullanılır?
              </Typography>
              <List>
                <ListItem>
                <ListItemAvatar>
                    <Avatar><MapsHomeWorkRounded></MapsHomeWorkRounded></Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Eşya seçin" secondary="Üst menüden kullanmak istediğiniz eşyaları seçin.">
                  </ListItemText>
                </ListItem>
                <ListItem>
                <ListItemAvatar>
                    <Avatar><MapsHomeWorkRounded></MapsHomeWorkRounded></Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Tüketim miktarlarını düzenleyin" secondary="Eşyaların gücünü ve günlük kullanım saatlerini belirleyin VEYA varsayılan haliyle bırakın.">
                  </ListItemText>
                </ListItem>
                <ListItem>
                <ListItemAvatar>
                    <Avatar><MapsHomeWorkRounded></MapsHomeWorkRounded></Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Uygun paketi bulun" secondary="Alttaki uygun paketi bulma butonu ile ihtiyacını olan solar sistemi görün.">
                  </ListItemText>
                </ListItem>
              </List>
            </Paper>
          </Grid>
        }

      </Grid>

      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={foundedLinkModalOpen}
        onClose={() => setFoundedLinkModalOpen(false)}
        scroll="paper"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">Sizin İçin Bulduğumuz Ürünler</DialogTitle>
        <DialogContent>
          <Paper >
            {foundedLinks && foundedLinks.length > 0 && (
              <Card
                sx={{ padding: (theme) => theme.spacing(2) }}
                variant={"outlined"}
              >
                  <Grid container spacing={2}>
                  {foundedLinks.map((foundedLink) => {
                    return (
                      <Grid item sm={12} md={6} lg={4}>

                      <Card >
                        <CardActionArea onClick={() =>
                          handleGoLink(foundedLink)
                        }>
                          <CardMedia
                            component="img"
                            height="140"
                            image={foundedLink.imageUrl}
                            alt={foundedLink.name}
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                              {foundedLink.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {foundedLink.explanation}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                        <CardActions>
                          <Button size="small" color="primary"
                            sx={{ mx: "auto" }}
                            onClick={() =>
                              handleGoLink(foundedLink)
                            }
                          >
                            Ürüne Git
                          </Button>
                        </CardActions>
                      </Card>

                    </Grid>
                    );
                  })}
                  </Grid>

              </Card>
            )}
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFoundedLinkModalOpen(false)}>Kapat</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
