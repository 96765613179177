import * as React from "react";

import { useState, useEffect } from "react";

import { FormControl, MenuItem, Select } from "@mui/material";
import { InputLabel } from "@mui/material";
import { FormHelperText } from "@mui/material";
import { OutlinedInput } from "@mui/material";

import { Box } from "@mui/material";
import {  Formik } from "formik";
import Grid from "@mui/material/Grid";
import Page from "../components/Page";
import * as Yup from "yup";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

import Switch from "@mui/material/Switch";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { categoryService } from "../services/categoryService";
import _ from "lodash";

import { Stack, Avatar, Button, Container, Typography } from "@mui/material";
import { productService } from "../services/productService";
import { toaster } from "../utils/bToaster";
import { EditRounded } from "@mui/icons-material";

export default function ProductEdit(props) {
  const initialProduct = props.initialProduct;
  const [categoryList, setCategoryList] = useState([]);
  const [image64, setImage64] = useState(initialProduct.imageUrl);

  useEffect(() => {
    categoryService.getAll().then(({ data }) => {
      let categories = data.data;
      categories = _.orderBy(categories, ["order"], ["asc"]);

      setCategoryList(categories);
    });
  }, []);

  const Input = styled("input")({
    display: "none",
  });

  const readBase64Str = (file) => {
    if (file) {
      var reader = new FileReader();
      reader.onload = function (e) {
        const base64 = e.target.result;
        setImage64(base64);
      };
      reader.readAsDataURL(file);
    }
  };

  const imageChanged = (e) => {
    readBase64Str(e.target.files[0]);
  };

  const removeImage = (e) => {
    setImage64("");
    e.preventDefault();
  };

  const renderImage = () => {
    if (image64) {
      return (
        <React.Fragment>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Avatar alt="UImg" src={image64} sx={{ width: 150, height: 150 }} />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <Button
              onClick={removeImage}
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            startIcon={<AddAPhotoIcon />}
            color="primary"
            aria-label="upload picture"
            component="span"
            variant="contained"
          >
            <AddAPhotoIcon sx={{ fontSize: 140 }} />
          </IconButton>
        </div>
      );
    }
  };

  const [isActive, setIsActive] = React.useState(initialProduct.isActive);

  return (
    <Formik
      initialValues={{
        name: initialProduct.name,
        imageUrl: initialProduct.imageUrl,
        power: initialProduct.power,
        order: initialProduct.order,
        categoryId: initialProduct.categoryId,
      }}
      validationSchema={Yup.object({
        name: Yup.string()
          .required("Ürün ismini boş bırakamazsınız.")
          .min(3, "Ürün ismi en az 3 karkater olmalıdır."),
        power: Yup.number()
          .required("Ürün gücünü giriniz.")
          .min(1, "Güç değeri 1 den az olamaz."),
        order: Yup.number()
          .required("Ürün sırasını giriniz.")
          .min(1, "Sıralama değeri 1 den az olamaz."),
        categoryId: Yup.number()
          .required("Kategori belirtiniz.")
          .min(1, "Kategori belirtiniz."),
      })}
      onSubmit={async (values, formikThings) => {
        formikThings.setSubmitting(true);
        const nValues = {
          ...values,
          imageUrl: image64,
          isActive,
          id: initialProduct.id,
        };
        productService
          .update(nValues)
          .then(({ data }) => {
            const product = data.data;

            toaster.success(
              `${product.name} isimli ürünü başarı ile güncellediniz.`
            );
            props.productUpdated(nValues);
          })
          .finally(() => {
            formikThings.setSubmitting(false);
          });
      }}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <Page title="Urun  Düzenleme">
            <Container>
              <Typography variant="h4" gutterBottom>
                Ürün Ekleme
              </Typography>
              <Stack direction="row" spacing={3}>
                <Typography sx={{ pb: 2 }} variant="body2" gutterBottom>
                  Ana Ekran
                </Typography>
                <ArrowRightIcon />
                <Typography sx={{ pb: 2 }} variant="body2" gutterBottom>
                  Ürün
                </Typography>
                <ArrowRightIcon />
                <Typography sx={{ pb: 2 }} variant="body2" gutterBottom>
                  Ürün Ekleme
                </Typography>
              </Stack>
              <Box
                sx={{
                  "& > :not(style)": { m: 1 },
                  maxWidth: "100%",
                  marginLeft: "10%",
                }}
                noValidate
                autoComplete="off"
              >
                <Grid container>
                  <Grid item xs={12} sm={6} md={3}>
                    <label htmlFor="icon-button-file">
                      {renderImage()}
                      {!image64 && (
                        <Input
                          onChange={(e) => imageChanged(e)}
                          accept="image/*"
                          id="icon-button-file"
                          type="file"
                        />
                      )}
                    </label>
                    <Box sx={{ mt: 5 }} />

                    <Divider orientation="horizontal" flexItem />
                    <Box sx={{ mt: 5 }} />

                    <Grid item xs={12} sm={6} md={6}>
                      <Stack direction="row" spacing={3}>
                        <Typography variant="body1" sx={{ pt: 1 }} gutterBottom>
                          Görünürlük:
                        </Typography>
                        <Switch
                          value={"qwe"}
                          checked={isActive}
                          onChange={() => {
                            console.log(!isActive);
                            console.log("active changed");
                            setIsActive(!isActive);
                          }}
                          name="loading"
                          color="primary"
                        />
                      </Stack>
                      <FormHelperText id="isActive">
                        Ürün Aktif mi?
                      </FormHelperText>
                    </Grid>
                  </Grid>
                  <Divider orientation="vertical" sx={{ ml: 5 }} flexItem />
                  <Grid item xs={12} sm={6} md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        "& > :not(style)": {
                          m: 1,
                          maxWidth: "100%",
                        },
                      }}
                    >
                      <Stack sx={{ width: "100%" }} direction="row" spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                          <FormControl sx={{ width: "100%" }}>
                            <FormControl sx={{ width: "100%" }}>
                              <InputLabel htmlFor="product_name">
                                Ürün İsmi
                              </InputLabel>
                              <OutlinedInput
                                {...formik.getFieldProps("name")}
                                id="product_name"
                                label="Power"
                              />
                            </FormControl>
                            {formik.touched.name && formik.errors.name ? (
                              <FormHelperText
                                sx={{
                                  color: (theme) => theme.palette.error.main,
                                }}
                              >
                                {formik.errors.name}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <FormControl sx={{ width: "100%" }}>
                            <FormControl sx={{ width: "100%" }}>
                              <InputLabel htmlFor="component-outlined">
                                Ürün Gücü
                              </InputLabel>
                              <OutlinedInput
                                {...formik.getFieldProps("power")}
                                id="product_power"
                                label="Power"
                              />
                            </FormControl>
                            {formik.touched.power && formik.errors.power ? (
                              <FormHelperText
                                sx={{
                                  color: (theme) => theme.palette.error.main,
                                }}
                                id="my-helper-text"
                              >
                                {formik.errors.power}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>
                      </Stack>

                      <Stack sx={{ width: "100%" }} direction="row" spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                          <FormControl sx={{ width: "100%" }}>
                            <FormControl sx={{ width: "100%" }}>
                              <InputLabel htmlFor="categoryId">
                                Kategori
                              </InputLabel>
                              <Select
                                {...formik.getFieldProps("categoryId")}
                                labelId={"categoryId"}
                              >
                                {categoryList.map((category) => (
                                  <MenuItem
                                    key={category.id}
                                    value={category.id}
                                  >
                                    {category.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              {formik.touched.categoryId &&
                              formik.errors.categoryId ? (
                                <FormHelperText
                                  sx={{
                                    color: (theme) => theme.palette.error.main,
                                  }}
                                  variant="error"
                                  id="my-helper-text"
                                >
                                  {formik.errors.categoryId}
                                </FormHelperText>
                              ) : null}
                            </FormControl>
                          </FormControl>{" "}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <FormControl sx={{ width: "100%" }}>
                            <FormControl sx={{ width: "100%" }}>
                              <InputLabel htmlFor="product_order">
                                Ürün Sıra
                              </InputLabel>
                              <OutlinedInput
                                {...formik.getFieldProps("order")}
                                id="product_order"
                                label="Order"
                              />
                            </FormControl>
                            {formik.touched.order && formik.errors.order ? (
                              <FormHelperText
                                sx={{
                                  color: (theme) => theme.palette.error.main,
                                }}
                              >
                                {formik.errors.order}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>
                      </Stack>

                      <Grid
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                        item
                        xs={12}
                      >
                        <Button
                          variant="contained"
                          margin-top="225px"
                          endIcon={<EditRounded />}
                          type={"submit"}
                        >
                          Düzenle
                        </Button>
                      </Grid>

                      <Paper elevation={0} />
                    </Box>
                  </Grid>{" "}
                </Grid>
              </Box>
            </Container>
          </Page>
        </form>
      )}
    </Formik>
  );
}
