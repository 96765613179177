export const urlHelper = {
  checkLinkIsValid(link) {
    let url;

    try {
      url = new URL(link);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  },
};
