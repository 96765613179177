import axios from "axios";
import { projectSettings } from "./projectSettings";
import { persistedStore } from "../redux/reduxStore";
import {
  BackDropCloseAction,
  BackDropOpenAction,
} from "../redux/backdropStore";
import { toaster } from "../utils/bToaster";

export const axiosBase = axios.create({
  baseURL: projectSettings.baseURL,
  headers: {
    Authorization: `Bearer y ${
      persistedStore.getState().auth?.user?.auth?.accessToken
    }`,
  },
});
axiosBase.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: `Bearer ${
        persistedStore.getState().auth?.user?.auth?.accessToken
      }`,
    };
    persistedStore.dispatch(BackDropOpenAction());
    return config;
  },
  (error) => {

   return Promise.reject(error);
  }
);

axiosBase.interceptors.response.use(
  (repsonse) => {
    persistedStore.dispatch(BackDropCloseAction());
    return repsonse;
  },
  (error) => {
    persistedStore.dispatch(BackDropCloseAction());
      toaster.error("Api ile iletişime geçilirken bir hata ile karşılaşıldı.");
    return Promise.reject(error);
  }
);
