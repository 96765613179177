import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import { useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink } from "react-router-dom";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import * as React from "react";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import ProductEdit from "./ProductEdit";
import ProductAdd from "./ProductAdd";
import _ from "lodash";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
} from "../components/_dashboard/user";
//
import { productService } from "../services/productService";
import { useAppDispatch } from "../redux/reduxStore";

import { toaster } from "../utils/bToaster";
import IconButton from "@mui/material/IconButton";
import { EditRounded } from "@mui/icons-material";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Ad", alignRight: false },
  { id: "power", label: "Güç", alignRight: false },
  { id: "categoryId", label: "Kategori ID", alignRight: false },
  { id: "click", label: "Tıklanma Sayısı", alignRight: false },
  { id: "isActive", label: "Aktiflik", alignRight: false },
  { id: "order", label: "Sırası", alignRight: false },
  { id: "", label: "Düzenle", alignRight: false },
];

//Modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_product) =>
        _product.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Products() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [productList, setProductList] = useState([]);


  React.useEffect(() => {
    productService.getAll().then(({ data }) => {
      setProductList(data.data);
    });
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = productList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - productList.length) : 0;

  const filteredProducts = applySortFilter(
    productList,
    getComparator(order, orderBy),
    filterName
  );

  const productAdded = (product) => {
    let nProductList = productList;
    nProductList.push(product);
    nProductList = _.orderBy(nProductList, ["order"], ["asc"]);
    console.log(nProductList);
    setProductList(nProductList);
    setOpen(false);
  };

  const productUpdated = (product) => {
    let nProductList = productList.map((p) =>
      p.id == product.id ? product : p
    );
    setProductList(nProductList);
    setEditOpen(false);
  };

  const isProductNotFound = filteredProducts.length === 0;

  // v For Modal
  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [editProduct, setEditProduct] = React.useState(null);
  const handleEditOpen = (product) => {
    console.log("you wanted edit this product");
    console.log(product);
    setEditProduct(product);
    setEditOpen(true);
  };
  const handleEditClose = () => setEditOpen(false);
  const deleteClicked = () => {
    productService.deleteBulk(selected).then(() => {
      const nProducts = productList.filter((s) => !selected.includes(s.id));
      toaster.success("Seçilen ürünler başarı ile silindi.");
      setProductList(nProducts);
      setSelected([]);
    });
  };

  return (
    <Page title="Ürün">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Stack direction="column">
            <Typography variant="h4" gutterBottom>
              Ürün
            </Typography>
            <Stack direction="row" spacing={3}>
              <Typography sx={{ pb: 2 }} variant="body2" gutterBottom>
                Ana Ekran
              </Typography>
              <ArrowRightIcon />
              <Typography sx={{ pb: 2 }} variant="body2" gutterBottom>
                Ürün Listesi
              </Typography>
            </Stack>
          </Stack>
          <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
            onClick={handleOpen}
          >
            Yeni Ürün
          </Button>
          <Modal
            aria-labelledby="create-modal"
            aria-describedby="create-modal"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <ProductAdd productAdded={productAdded} />
              </Box>
            </Fade>
          </Modal>
          <Modal
            aria-labelledby="edit-model"
            aria-describedby="edit-modal"
            open={editOpen}
            onClose={handleEditClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={editOpen}>
              <Box sx={style}>
                <ProductEdit
                  initialProduct={editProduct}
                  productUpdated={productUpdated}
                />
              </Box>
            </Fade>
          </Modal>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            selectedIds={selected}
            deleteClicked={deleteClicked}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={productList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredProducts
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        id,
                        name,
                        imageUrl,

                        power,
                        categoryId,
                        clickCount,
                        isActive,
                        order,
                      } = row;
                      const isItemSelected = selected.indexOf(id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          category="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, id)}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar alt={name} src={imageUrl} />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{power}</TableCell>
                          <TableCell align="left">{categoryId}</TableCell>
                          <TableCell align="left">{clickCount}</TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={
                                (isActive === false && "error") || "success"
                              }
                            >
                              {sentenceCase(isActive ? "Aktif" : "Pasif")}
                            </Label>
                          </TableCell>
                          <TableCell align="left">{order}</TableCell>

                          <TableCell align="left">
                            <IconButton
                              color="primary"
                              aria-label="edit"
                              component="span"
                              onClick={() => handleEditOpen(row)}
                            >
                              <EditRounded />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isProductNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={productList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
