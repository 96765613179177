import {merge} from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import {Box, Card, CardHeader} from '@mui/material';
// utils
import {fNumber} from '../../../utils/formatNumber';
//
import {BaseOptionChart} from '../../charts';
import {useState, useEffect} from "react";
import {linkService} from "../../../services/linkService";

// ----------------------------------------------------------------------

export default function AppConversionRates() {
    const [chartData, setChartData] = useState(null);
    const [linkStats, setLinkStats] = useState(null);

    const chartOptions = merge(BaseOptionChart(), {
        tooltip: {
            marker: { show: false },
            y: {
                formatter: (seriesName) => fNumber(seriesName),
                title: {
                    formatter: (seriesName) => `#${seriesName}`
                }
            }
        },
        plotOptions: {
            bar: { horizontal: true, barHeight: '28%', borderRadius: 2 }
        },
        xaxis: {
            categories: linkStats !== null ? linkStats.linkNames : []
        }
    });

    useEffect(() => {
        linkService
            .linkStats()
            .then(resp => {
                const {data: responseData} = resp;
                if (responseData.isSuccess) {
                    const iData = responseData.data;
                    setLinkStats(iData);
                    setChartData([{ data: [...iData.clickCounts] }]);

                }
            })
    }, [])


    return (

        <>
            {linkStats &&  chartData && (<Card>
                <CardHeader title="İncelenme Dağılımı" subheader="Ürün paketlerinin incelenme dağılımı"/>
                <Box sx={{mx: 3}} dir="ltr">
                    <ReactApexChart type="bar" series={chartData} options={chartOptions} height={364}/>
                </Box>
            </Card>)}
        </>
    );
}
