// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import {useAppDispatch, useAppSelector} from "./redux/reduxStore";
import {Backdrop, CircularProgress} from "@mui/material";
import {BackDropCloseAction} from "./redux/backdropStore";

// ----------------------------------------------------------------------

export default function App() {
    const backDrop = useAppSelector(b => b.backdrop);
    const appDispatcher = useAppDispatch();
    const handleClose = ()=>{
            appDispatcher(BackDropCloseAction());
    }
  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <Router />
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} onClick={handleClose} open={backDrop.open}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </ThemeConfig>
  );
}
