import { axiosBase } from "./axiosBase";
const TOTAL_PRODUCT_COUNT = "link/totalcount";
const TOTAL_PRODUCT_CLICK = "link/totalclick";
const BASE = "link";
const BULK_DELETE = "link/deletebulk";
const CHECK_WATT_LINKS = "checkwattlinks";
const INCREMENT_CLICK = "incrementclickcount";
const LINK_STATS  = "link-stats"

export const linkService = {
  getTotalCount: () => {
    return axiosBase.get(TOTAL_PRODUCT_COUNT);
  },
  getTotalClick: () => {
    return axiosBase.get(TOTAL_PRODUCT_CLICK);
  },
  getAll: () => {
    return axiosBase.get(BASE);
  },
  add: (values: any) => {
    return axiosBase.post(BASE, values);
  },
  deleteBulk: (deletedIds: []) => {
    const dObj = { Ids: deletedIds };
    return axiosBase.post(BULK_DELETE, dObj);
  },
  update: (product: any) => {
    return axiosBase.put(`${BASE}/${product.id}`, product);
  },
  checkWattLinks : (watt :number) => {
    return axiosBase.post(`${BASE}/${CHECK_WATT_LINKS}?watt=${watt}`);
},
  incrementClick : (linkId :number) => {
    return axiosBase.post(`${BASE}/${INCREMENT_CLICK}?linkId=${linkId}`);
  },
  linkStats : () =>{
    return axiosBase.get(`${BASE}/${LINK_STATS}`);
  }
};
