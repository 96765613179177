import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Categories from './pages/Categories';
import Links from './pages/Links';
import NotFound from './pages/Page404';
import { CustomerMain } from './pages/CustomerMain';
import { CustomerDashboard } from './pages/CustomerDashboard';
import CustomerLayout from './layouts/CustomerLayout';
import pageConstants from './utils/pageConstants';
// ----------------------------------------------------------------------

export default function Router() {
	return useRoutes([
		{
			path: '/dashboard',
			element: <DashboardLayout />,
			children: [
				{ element: <Navigate to='/dashboard/app' replace /> },
				{ path: 'app', element: <DashboardApp /> },
				{ path: pageConstants.products.base, element: <Products /> },
				{ path: pageConstants.category.base, element: <Categories /> },
				{ path: pageConstants.links.base, element: <Links /> },
			],
		},
		{
			path: '/customer',
			element: <CustomerLayout />,
			children: [
				{ element: <Navigate to='/customer/dashboard' replace /> },
				{ path: 'dashboard', element: <CustomerDashboard /> },
				{ path: 'calculator', element: <CustomerMain /> },
			],
		},
		{
			path: '/',
			element: <LogoOnlyLayout />,
			children: [
				{ path: 'login', element: <Login /> },
				{ path: '404', element: <NotFound /> },
				{ path: '/', element: <Navigate to='/customer' /> },
				{ path: '*', element: <Navigate to='/404' /> },
			],
		},
		{ path: '*', element: <Navigate to='/404' replace /> },
	]);
}
